// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}
#stgType {
	height: 51px;
}


/* GRAVITY FORMS */
@media screen and (min-width: 641px) {  
	.gform_wrapper textarea.large { height: 160px !important; width: 100%; }
	body .gform_wrapper ul.gfield_radio li { display: inline; padding-right: 10px!important; }  /* to display radio fields inline */
}
body .gform_wrapper ul li.field_description_below div.ginput_container_radio,
body .gform_wrapper ul li.field_description_below div.ginput_container_checkbox,
body .gform_wrapper.gf_browser_chrome .gfield_checkbox li input, 
body .gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox], 
body .gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio] { margin-top: 0; }

.userCompanyEmail {display: none;}





