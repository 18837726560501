.btn {
	border-radius: 0.3rem;
	transition: all 0.2s ease-out 0s;
	text-transform: uppercase;
	line-height: 1.5rem;
	font-weight: 600;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	
	&.brand {
		background: linear-gradient(lighten($brand-primary, 3%), $brand-primary, darken($brand-primary, 3%));
		color: $brand-primary-text;

		&:hover {
			background: linear-gradient(lighten($brand-primary, 12%), lighten($brand-primary, 8%), lighten($brand-primary, 3%));
			box-shadow: $dp-2;
		}
		&:active  {
			background-color: darken( $brand-primary, 10% );
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			width: 120px;
			height: 120px;
			margin-left: -60px;
			margin-top: -60px;
			background-color: lighten( $brand-primary, 30% );
			border-radius: 100%;
			opacity: .2;

			transform: scale(0);
		}
	}
	&.brand-secondary {
		background: linear-gradient(lighten($brand-secondary, 3%), $brand-secondary, darken($brand-secondary, 3%));
		color: $brand-secondary-text !important;

		&:hover {
			background: linear-gradient(lighten($brand-secondary, 12%), lighten($brand-secondary, 8%), lighten($brand-secondary, 3%));
			box-shadow: $dp-2;
		}
		&:active  {
			background-color: darken( $brand-secondary, 10% );
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			top: 50%;
			width: 120px;
			height: 120px;
			margin-left: -60px;
			margin-top: -60px;
			background-color: lighten( $brand-secondary, 30% );
			border-radius: 100%;
			opacity: .2;

			transform: scale(0);
		}
	}
	&.btn-black {
		background: $black;
		color: $white;
	}
	&.btn-white {
		background: linear-gradient(lighten($very-light-gray, 3%), $white, darken($very-light-gray, 3%));
		border: 1px solid $brand-secondary;

		&:hover {
			background: linear-gradient(lighten($very-light-gray, 5%), $white, lighten($very-light-gray, 1%));
			box-shadow: $dp-1;
		}
	}
	
}

/* match Gravity forms to button above */
body .gform_wrapper .gform_footer input.button, 
body .gform_wrapper .gform_footer input[type=submit] {
    padding: .375rem .75rem;
    font-size: 1rem;	
	border-radius: 0.3rem;
	transition: all 0.2s ease-out 0s;
	text-transform: uppercase;
	line-height: 1.5rem;
	font-weight: 600;
	overflow: hidden;
	position: relative;
	cursor: pointer;
	background: linear-gradient(lighten($brand-secondary, 3%), $brand-secondary, darken($brand-secondary, 3%));
	color: $brand-secondary-text !important;
	border:0; 
	&:hover {
		background: linear-gradient(lighten($brand-secondary, 12%), lighten($brand-secondary, 8%), lighten($brand-secondary, 3%));
		box-shadow: $dp-2;
	}
}

a.custom-btn {  /* outline only */ 
	border: 2px solid #f7a501; 
	padding: 10px 20px 8px; line-height:1; font-weight:400; 
	color:#f7a501 !important;
	text-decoration:none !important; text-transform:uppercase;}
a.custom-btn:hover {  border-color: #ddd; background: #ddd;  }



@keyframes ripple {
	0% {
		transform: scale(0);
	}
	20% {
		transform: scale(1);
	}
	100% {
		opacity: 0;
		transform: scale(1);
	}
}
.btn:not(:active):after {
  animation: ripple 1s ease-out;
}
.btn:after {
	visibility: hidden;
}
.btn:focus:after {
	visibility: visible;
}