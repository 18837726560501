.blog {
	article {
		padding-bottom: 3rem;
		.entry-meta {
			padding-bottom: 1.5rem;
		}
	}
}

.single {
	article {
		padding-top: 0;
	}
	.entry-meta {
		padding-bottom: 1.5rem;
	}

	.entry-content {
		.row a img  {
			padding: 10px 0px;
			object-fit: cover;
			width: 100%;
			height: 100%;
			
		}		
	}

}

section.widget {
	padding: 0;
	margin:0;
}

.blog-sidebar {
    padding-top: 0;
}

.blog-spacing {
	margin-top:3em;	
}

.blog #content, 
.single #content {
	margin-top: 1.5rem;
}

