header.banner {
	padding: .5rem 0 0 0;
	position: fixed;
    width: 100%;
    top: 0px;	
	z-index: 9999;
	background-color: $dark-green;  /*
	transition: all 0.5s;*/

	.logo {
		&.valign-bottom {
			img {
				@include media-breakpoint-up(md) {
					position:absolute;
					bottom:0;
				}
			}
		}
	}
	
	.tagline {
		color:#009939; 
		font-size:1.36rem; 
		font-style:italic; 
		font-weight:300; 
		float:left; 
		padding-left:70px;  
	
		@media only screen and (max-width: 767px) {
			padding-left:60px;
			font-size:1rem; 
		}
	}
	
	img.logo { 
		max-width:150px; 
		width:150px; 
		height:150px; 
		position:absolute;  
		
		@media only screen and (max-width: 1199px) {
			max-width:90px;
			width:90px;
			height:90px;
		}		
	}	
	
	#menu-header-menu {
		@include media-breakpoint-up(md) { }
		li {
			padding: 0 .2rem .33rem .2rem;
			&:last-child {
				padding-right: 0;
			}
			&:after {
				content: "|";
    			color: $med-green;
			}
			a {
				color: #fff;
				font-size: 0.85rem;
				font-weight: 300;
				text-transform: uppercase;
				line-height: 30px;  /* to align header nave and phone */
				padding-right: .75rem;
				&.phone { 
					padding-right:0; font-size:1.5rem; color:#fff;
				}
			}
			/*&.phone a {
				font-size:1.25rem;
				text-decoration:none;
			}
			&.phone:after {
				content: "";				
			}
			@include media-breakpoint-down(md) {
				&.phone { display:none; }
			} */
		}
	}

	
	.mobile-phone {  
		display:none; 
		@include media-breakpoint-down(md) { display:block; }
		font-size: 20px;
		font-weight: 500;
		text-decoration: none !important;
		color: $brand-primary;
	}	
	
	.nav-primary {
		transition: margin-top 0.1s;

		li {
			transition: border-color 0.2s ease;
			padding: 0;
			& > ul {
				display: none;
			}
			a {
				padding: .5rem 1rem;
				display: block;
				color: #fff; 
				text-decoration: none;
				font-weight: 600;
				font-size: 0.9rem;
				text-transform: uppercase;

				&:hover {
					text-decoration: none;
				}
				.active {
					color: #ffad00;
				}
			}
			&.first a { padding-left: 0; }
			&.last a { padding-right: 0; }				
			&:hover {
				& > ul {
					display: block;
					position: absolute;
					background-color: $brand-primary;
				}
				a {
					/*color: $brand-primary; */
					&:hover {
						/*color: $brand-secondary; */
					}
				}
				.sub-menu {
					a {
						/*color: $brand-primary; */
						&:hover { 
							/*opacity: .7;*/ 
							background-color: rgba(0,0,0,.1); 
							/* color:#fff; */
						}
					}
				}
			}
			.sub-menu {
				margin: 0;
				padding: 15px 0;
				z-index: 9999;

				li {
					list-style-type: none;
					position: relative;
					padding: 0 20px; 
					
					a { text-transform: none; } 
					.sub-menu {
						width: 100%;
						position: absolute;
						left: 100%;
						top: 0;
					}
				}
			}
		}
		.current-menu-item {
			a {
				color: $brand-secondary;
			}
		}
	}
}

header.banner .nav-primary li.menu-contact { margin-right:15px; }	
header.banner .nav-primary li.menu-contact:after, header.banner .nav-primary li.menu-request-a-quote:after, header.banner .nav-primary .sub-menu li:after { content: ""; }		
header.banner .nav-primary li.menu-request-a-quote a { background:#feba01; color:#023414; font-weight:700; }

section.banner-slider {
	
	@media only screen and (max-width: 767px) {
		background-position: left !important;
		min-height:100px !important; 
	}
	
	.container {
		.row {
			.col-md-5 {
				@media only screen and (max-width: 767px) {
					margin-right:150px;
				}
				@media only screen and (max-width: 475px) {
					margin-right:0px;
				}
			}
		}
	}
}

@media only screen and (min-width: 1199px) {
	#Xslider {
			margin-top: 50px !important;	
	}
}

.wrap  {
	margin-top: 5rem;
	transition: margin-top 0.1s;
}
.fixed-header {
	header.banner {
		box-shadow: $dp-2;
	}
}



/*  media queries and responsive  */
.fixed-header-logo { display: none; }
.navbar-toggle { display: none; }

@media (min-width: 993px) and (max-width:1199px) {  /* prevent wrapping at 1024 */
	header.banner {
		.nav-primary {
			li {}
		}
	}
}
@media only screen and (min-width: 1199px) {
	#menu-mobile-menu {
		display:none;
	}
}
@media only screen and (max-width: 1199px) {
	header.banner {
		.navbar-toggle {
			display: block;
			padding: 0.75rem;
			float: right;
			background: transparent;
			border: 0;
			color:white;
			.icon-bar {
				background: $brand-secondary;
			}
		}
		.navbar-toggle.offcanvas-toggle.js-offcanvas-has-events {
			position: absolute;
			right: 5px;
			top: -43px;
		}
		.navbar-offcanvas.in {
			left:0px;
		}
		#menu-header-menu{
			display:none;
		}
		#menu-mobile-menu {
			display:inline;
		}		
	}
}

@media only screen and (max-width: 1199px) {
	header.banner {
		/*padding-top: 0.5rem;
		padding-bottom: 0.25rem;*/
		box-shadow: $dp-6;

		.custom-logo-link {
			margin: 0 auto;
			/*.custom-logo {
				max-width: 70%;
			}*/
		}
		.main-nav {
			margin-top: 0;
		}
		.justify-content-end {
			justify-content: flex-start !important;
		}
	}
	.wrap {
		margin-top: 4rem;
	}
	.main-nav .navbar-offcanvas.offcanvas-transform.in {
		transform: translateX(250px) !important;
	}
	#menu-primary-navigation {
		display:none;
	}
	.page-template-template-corporate .menu-main-container{
		display:none;
	}
	
}

@media only screen and (max-width: 991px) {
	.nav-primary .navbar-offcanvas.in {
		left: -250px !important;
	}
}

header.banner img.logo { transition: all 0.5s ease-in-out; }
body.fixed-header header.banner img.logo { max-width:90px; width:90px; height:90px; }

@media only screen and (max-width: 1199px) { 
	body.fixed-header header.banner img.logo { 
		max-width:70px; 
		width:70px; 
		height:70px; 
	}
}    

@media (max-width: 1199px) {
	header.banner {
		.navbar-toggle.offcanvas-toggle.js-offcanvas-has-events {
			position: absolute;
			right: 10px;
			top: -34px;
		}
	}
}



/* fix for fixed header not lining up with admin bar */
/*.admin-bar header {
	top: 32px;
}*/