section#featured-testimonial {
	background: $med-green;
	color:#fff;
	margin-bottom: 0;
	padding: 3rem 0;
	.horiz-rule { 
		position: absolute;
		top: 0;
		height: 18px;
		border-bottom: 1px solid #feba01;
		width: 100%;
		z-index: 5;	
	}
	h2 {
		position:relative;
		display:inline;
		padding: 0 30px;
		font-size: 1.5rem!important;
		color: $gold; 
		background: $med-green; 
		z-index:50;  
	}
	.photo { 	
		position: relative;
		width:170px; height:170px; overflow: hidden;
		margin: 0 0 20px auto;
		border: 4px solid #feba01;
		border-radius: 100%;
		box-shadow: 0 2px 0 2px rgba(0,0,0,.25);
		transition: all .35s ease; 
		img { 
			max-width: 100%; width:100%; 
			height: auto;  display: block;
		}	
	}
}

#offer {
    margin: 0;
    padding: 3rem 0;
    color: #333;
    /* background: #023414; */ 
	background: #025b23;
	color:#fff;
}
#offer .quote-box {
    color: #023414;
    font-size: 2.66rem;
    font-weight: 300;
    line-height: 1.25;
    text-align: center;
    background-color: #f5f5f5;
    margin: 0 0 0 160px;
    padding: 25px 35px;
} 
@media only screen and (max-width: 991px) {
	#offer .quote-box {
		margin: 0px;
	}	
}

#offer .triangle {
	width: 0; height: 0; border-style: solid; border-width: 50px 90px 0 0px;
	border-color: #f5f5f5 transparent transparent transparent;
	text-align: right;  margin-left: 220px;
	/* @include media-breakpoint-down(md) { margin-left:25px; }			 */
}	
#offer .btn { background: #feba01; border-radius:0; }
.main #offer a.btn  { color: #023414;}


#footer-cta {
	background: $brand-secondary;
	color: #fff;
	font-size: 2.2rem;
	font-weight: 600;
	padding: 30px 0;
	margin-bottom: 0;
	a.btn {
		margin-left:25px;
	}
	@include media-breakpoint-down(md) {
	
	}
}

footer.site-footer {
	/* background: $primary-gradient; */
	color: $body;
	a { 
		color:$body; 
	}
	padding: 3rem 0;
	font-size: 15px; 

	.widget {
		.heading { }
	}	
	
	ul.menu {
		list-style-type: none;
		padding: 0;
		li { padding-bottom:.5rem;}
		a { text-decoration: underline; }
		a:hover { text-decoration: none; }
	}	
	.phone { font-size:1.5rem; line-height:1;  }
}

@media (max-width: 992px) {}
@media (max-width: 768px) {}
@media (max-width: 544px) {}