#slider {
	padding: 0;
	.btn {
		font-size: 0.9rem;
		font-weight: 400;
		text-transform: none;
		line-height: 1rem;
		padding: 0.5rem 1rem 0.75rem 1rem;
	}
	.carrot {
		color: $brand-secondary;
		padding: 0 0.5rem;
		font-size: 1.25rem;
		line-height: 1rem;
	}
	.slide-left {
		padding: 3.5rem 0 3rem 0;
		/*background: url('/wp-content/themes/tamarin/dist/images/stg_homepage.jpg') top left no-repeat; */
		background-size: cover;
		h1 {
			color: $white;
			font-size: 3rem;
			font-weight: 500;
			margin-top: 3rem;
			animation: 1s ease-out 0s 1 slideInFromLeft;
		}
		p {
			color: white;
			font-size: 1rem;
			font-weight: 300;
			margin-top: 2rem;
			animation: 1s ease-out 0s 1 slideInFromRight;

		}
		a.btn {
			animation: 1s ease-out 0s 1 slideInFromLeft;
		}
	}
	.slide-right {
		background: url('/wp-content/themes/tamarin/dist/images/home-bg-2.jpg') top left;
		padding: 2rem 0;

		.row > .col {
			padding: 2.5rem 0;
		}
		.row:first-child > .col {
			animation: 1s ease-out 0s 1 slideInFromLeft;
			.btn {
				float: left !important;
				
			}
		}
		.row:last-child > .col {
			animation: 1s ease-out 0s 1 slideInFromRight;
			.btn {
				float: left !important;
				
			}
		}
		h2 {
			color: $white;
			font-size: 1.5rem;
			font-weight: 500;
		}
		h3 {
			color: $white;
			font-size: 1rem;
			font-weight: 300;
			margin-bottom: 1rem;
		}
	}

}

/* TABS */
.tab-set {
	margin: 0 0 -1px 0;
	padding:0;
	list-style:none;
}
.tab-set > li { 
	display:inline-block;
	padding: 1rem; 
	background:$light-gray; 
	border-top: 1px solid $almost-gray;
	border-left: 1px solid $almost-gray;
	cursor: pointer;
	font-size: 1.125rem;
	text-transform: uppercase;  
}
.tab-set > li:last-child { 
	border-right: 1px solid $almost-gray;
}
.tab-set > li.active {
	background: #fff; 
	border-top: 3px solid $brand-secondary; 
	border-bottom: 1px solid #fff; 
}  
.tab-set > li > a, 
.tab-set > li > a:hover, 
.tab-set > li > a:focus { 
	border-radius: 0; 
	text-transform:uppercase;  
}
.tab-set > li.active > a, 
.tab-set > li.active > a:hover, 
.tab-set > li.active > a:focus { 
	border-top:0 
}
.tab-set > li:first-child.active a { 
	border-left: 2px solid $almost-gray; 
}
@media screen and (max-width:767px) {
	.tab-set { display:block;  }
	.tab-set>li { float: none; margin-bottom: -1px; width:100%; border-right: 1px solid $almost-gray;  }
	/* todo add mobile padding rules */ 
}

.tab-content { 
	padding: 1.2rem; 
	border: 1px solid $almost-gray; 
	/*img { clear:left; } */
	/*#tab_Gallery {
		img {
			clear:none;
			width:242px; height:auto;
			max-width:242px;
    	}
	} */
    /*a.btn {display:table;clear:left; } */
} 
.tab-content > div { display: none; } 
.tab-content > div.active { /*display: block;*/ display:table; width:100%; }  /*use table to contain floating images */


/* ACCORDION */
#accordion { /* background: $test-gradient; */ }
.accordion {
	box-shadow: $dp-2;
	margin-bottom: 1rem;
	.accordion-title {
		padding: 1rem;
	}
	.accordion-label {
		width: 100%;
		background: $white;
		color: $body;
		text-align: left;
		padding: 1rem;
		border-top: none;
		border-right: none;
		border-bottom: 1px solid $light-gray;
		border-left: none;
		cursor: pointer;

		&:after {
			content: "+";
			float: right;
			font-size: 1em;
			font-weight: bold;
			color: $brand-secondary;
			transition: transform 0.3s ease-in-out;
		}
		&.active {
			&:after {
				transform:rotate(-135deg);
			}
		}
	}
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		list-style-type: none;
	}
	li > ul {
		max-height: 0;
		overflow: hidden;
		transition: all 0.35s ease-in-out;
	}
	.accordion-content li {
		padding: 1rem;
		background: $white;
	}
}


/* CARDS */
.card {
	border: none;
	box-shadow: $dp-2;
	border-radius: 0;
	.card-img-top { }
}
.card-bg-primary {
	/* background: lighten( $brand-primary, 82% ); */
	/* background: rgba( $brand-primary, 0.07 ); */
	background: $brand-primary;	
}
.card-bg-secondary {
	/* background: lighten( $brand-secondary, 38% ); */
	/* background: rgba( $brand-secondary, 0.07 ); */
	background: $brand-secondary;
}
.card-bg-custom {
	background: $dark-green;
	a { color:#fff; text-decoration:none; }
}
.card-block {
	text-align:center;
	padding: 1rem .5rem;
}


/* EKKO-LIGHTBOX MODALS */

.modal-open .modal {  z-index: 9999; }

.modal-content {
	background: transparent;
	border: none;
	border-radius: 0;
	& > .modal-header {
		border-bottom: 0;
		padding: 5px 5px 0px 0px;

		& > .close {
			color: $white;
			opacity: 1;
			&:focus { opacity: 1;       }
			&:hover { opacity: 1;       }
		}
	}
	.ekko-lightbox-nav-overlay {
		top:43%; position:absolute; width:100%;
		& > a {
			color: $white;
			padding: 2px !important;
			opacity: 1;
			font-size:46px;
			text-decoration:none;
			background:$black;
			
			&:first-child { float:left; } 		
			&:last-child { float:right; margin-right:5%; } 
		}
	}
}

/* move arrows to outside of modal */
.ekko-lightbox .modal-content { background:#000; color:#fff;  }	 
.ekko-lightbox .modal-footer {  border-top:0; justify-content:left;   }
.ekko-lightbox { align-items: center; display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;}	
.modal-content .ekko-lightbox-nav-overlay { width: calc(100% + 50px); left:-25px; }	
.modal-content .ekko-lightbox-nav-overlay>a:last-child { margin-right: 0; }	
.modal-content>.modal-header>.close {background: #feba01; border-radius:50%; padding:0; margin:0; height:30px;width:30px; 
    color: #fff;
    opacity: 1;
}

.menu-social-media-container {
	a {
		display: block;
		height: 24px;
		width: 24px;
		text-indent: -9999em;
		overflow: hidden;
	}
	li {
		background-position: top center;
		background-size: contain;
		margin: 0 0.2rem 0 0;
	}
	.linkedIn {
		background-image: url('/wp-content/themes/tamarin/dist/images/32-linkedin.png');
	}
	.twitter {
		background-image: url('/wp-content/themes/tamarin/dist/images/32-twitter.png');
	}
	.facebook {
		background-image: url('/wp-content/themes/tamarin/dist/images/32-facebook.png');
	}
	.instagram {
		background-image: url('/wp-content/themes/tamarin/dist/images/32-instagram.png');
	}
	.youtube {
		background-image: url('/wp-content/themes/tamarin/dist/images/32-youtube.png');
	}	
}

/* OFFCANVAS NAV */
.offcanvas-toggle.is-open .icon-bar:nth-child(3) {  /* Noli: X not ligning up with larger span size for hamburger */
    -webkit-transform: rotate(-45deg) translate(5px,-5px);
    transform: rotate(-45deg) translate(5px,-5px);
}

#js-bootstrap-offcanvas {
	background: $med-green;
}
.navbar-toggle {
	.icon-bar {
		display: block;
		width: 25px;
		height: 3px;
		border-radius: 1px;
	}
	.icon-bar+.icon-bar {
		margin-top: 4px;
	}
}
header .nav-primary {
	.in .nav {
		display: table;

		& > li a {
			font-weight: 700;
		}
		li {
			padding: 0;

			a {
				padding: 0.6em 0 0.5em;
				font-size: 1em;
				border-bottom: 1px solid $almost-gray;
			}
		}
		li .sub-menu {
			display: block;
			position: relative;
			left: auto;
			top: auto;
			padding-left: 0.5em;
			padding-top:0;
			padding-bottom: 0;

			a {
				font-weight: 400;
			}

			li .sub-menu {
				display: block;
				position: relative;
				left: auto;
				top: auto;
			}
		}
		li:hover ul {
			border-left: 0;
			border-right: 0;
			border-bottom: 0;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			box-shadow: none;
			background: inherit;
			a {
				padding: .6em 0 .5em;
			}
		}
	}
}
@media (max-width: 1199px) {
	.navbar-offcanvas {
		position: fixed;
		width: 100%;
		max-width: 300px;
		height: 100%;
		left: -300px;
		top: 0;
		padding-left: 15px;
		padding-right: 15px;
		z-index: 999;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		transition: all .15s ease-in;
	}
}
/*.fixed-header header.banner .nav-primary .in li a {
    padding: .6em 0 .5em;
} */

/* media queries */
@media (max-width: 992px) {
	header.banner .nav-primary li a {  /* OK */
		text-transform: capitalize !important;
	}
	/*.main-nav #js-bootstrap-offcanvas {
		background: $white;
	}
	header .nav-primary .in .nav li.menu-item-has-children > a {
		color: $brand-secondary !important;
	}*/
	
	.slide-text {   /* check on this */
		margin: 1rem 0;
		h2 { padding-top: 0 !important; }
	}	
}

@media (max-width: 768px) {
	.slide-text {  /* check on this */
		margin: 1rem 0;
		h2 { padding-top: 0 !important; }
	}
}

.row.no-gutters {
	margin-right: 0 !important;
	margin-left: 0 !important;
	& > [class^="col-"],
	& > [class*=" col-"] {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
}


/* KL  from http://uakari.walkupright.com/wp-content/themes/tamarin/bower_components/slick-carousel/slick/slick-theme.css (not loading this url) */
@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
    background: #fff url('./ajax-loader.gif') center center no-repeat;
}
/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.eot');
    src: url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.woff') format('woff'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.ttf') format('truetype'), url('/wp-content/themes/tamarin/bower_components/slick-carousel/slick/fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 46%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 1;
    opacity: .90;
    color: $med-green;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    /*left: -25px; */
	left: -38px;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    /*content: 'â†';*/
	content: "←";
}
[dir='rtl'] .slick-prev:before {
    /*content: 'â†’';*/
	content: "←";
}
.slick-next {
    right: -19px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    /*content: 'â†’';*/
	content: "→";
}
[dir='rtl'] .slick-next:before {
    /*content: 'â†';*/
	content: "→";
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    /*content: 'â€¢';*/
	content: "•";
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}

.product-carousel img { width:200px; max-width:200px; height:160px;overflow:hidden;object-fit:cover; margin:0 auto;  }
.custom-conveyors .product-carousel img { width:198px; max-width:198px; height:160px;overflow:hidden;object-fit:cover; margin:0 auto;  }     
.page-template-template-product .product-carousel img { border: 4px solid #fff;  }

section#carousel .horiz-rule {
    position: absolute; top: 0; height: 18px; border-bottom: 1px solid #929292;
	width: calc(100% - 30px); z-index: 5; } 
section#carousel h2 {
    position: relative;display: inline; padding: 0 30px;
    color: #929292; background: #ededed; z-index: 50;
}

@media only screen and (max-width: 767px) {
	.product-carousel img { 
		width:150px; 
		max-width:150px; 
		height:150px;
		overflow:hidden;
		object-fit:cover; 
		margin:0 auto;  
	}
	.custom-conveyors .product-carousel img	{ 
		width:100%; 
		max-width:100%; 
		height:150px;
		overflow:hidden;
		object-fit:cover; 
		margin:0 auto;  
	}	
}	
	
@media only screen and (max-width: 814px) {
	.slick-prev:before,
	.slick-next:before {
		display:none;
	}
}
