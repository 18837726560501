.home {
	#content {
		margin-top:10rem;
		
		h1 {
			margin-bottom:50px;
		}
	}
	.row.lauyans_sites .col-lg-4 {
		text-align:center;
		color:$white !important;
		margin-bottom:20px;
		
		img {
			width:100%;
		}
		
		h2 {
			color:$white !important;
			margin-top:15px;
		}
	}
	a.btn.corporate {
		margin-top:15px;
		display:block;
		background-color:$gold !important;
	}
	.engineered_products_site_box {
		background-color:$med-green;
		padding:10px; 
		border-radius:4px;
	}
	.chainveyor_site_box{
		background-color:$dark-purple;
		padding:10px; 
		border-radius:4px;		
	}	
	.metal_katcher_site_box {
		background-color:$dark-red;
		padding:10px; 
		border-radius:4px;		
	}
}

.custom-conveyors {
	#services {
		text-align: center;
		h2 {
			text-transform: uppercase;
			text-align: left;
			font-size: $font-size-base*1.5;
			font-weight: 400;
			margin-bottom: 2rem;
		}
		h3 {
			font-size: $font-size-base*1.75;
			font-weight: 500;
			position: relative;
			text-align: center;
			margin-bottom: 0.75rem;
		}
		a.btn {
			font-weight: 400;
			font-size: 0.9rem;
		}
		img {
			margin-bottom: 2rem;
		}
	} 
	#testimonials {
		h2 {
			margin-bottom: 3rem;
			position: relative;
		}
		h2:after {
			content:'';
			width: 2.5rem;
			height: 2px;
			background: $brand-secondary;
			position: absolute;
			left: 48%; /* temp */
			bottom: -0.75rem;
		}
		.blockquote, .blockquote-footer {
			font-size: 1rem;
			padding: 0 1rem;
		}
		.slick-next::before, .slick-prev::before {
			color: $brand-secondary;
			font-size: 2rem;
		}
		.slick-prev {
			left: -4rem;
		}
		.slick-next {
			right: -4rem;
		}
  }
} 

/* CAREERS PAGE */
.career-post-sidebar { 
	.btn { width:100%; }
}
.career-post-footer { 
	@include media-breakpoint-down(md) {
		display:none;
	}
}

/* RESOURCES PAGE - 2610 = RESOURCES PAGE */
.ebook-list{
	color:white;	
}	
.ebook-list .ebook-background{
	background-color:#1D5B23;
	padding:1em;
	border-radius:5px;
}	
.page-id-2610 .btn.brand {
	background: #FBBA02;
	color:black;
}

.page-template-template-product {
	#video-panel {
		background-color: $light-gray;
	}
	#resources {
		.btn {
			width: 100%;
		}
	}
	#need-help {
		background-color: $dark-gray;
		color: $white;
		h2 {
			color: $white;
		}
	}
}

// Features - Rollovers
.page-template-template-features-rollovers {
	section {
		background: $test-gradient;
	}
	section .container > h2, section .container > h3, section .container > h4, section .container > h5, section .container > h6 {
    	font-weight: 300;
    	color: $brand-primary;
	}
	#content {
    	padding-top: 3.15rem;
    	background: $white;
	}
	.divider {
    	padding: 0 0;
	}
}

.box {
	transition: transform 0.1s;
	position: relative;
}
#feature-modules {
	background: $test-gradient;
	padding-top: 3.15rem;
	& > .container {
		& > .row {
			padding-bottom: 25px;
		}
	}
	.card { margin-bottom: 2em; }
}


/* Scale */
.rollover-scale {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
	backface-visibility:         hidden;
	transform: translateZ(0);
	font-smoothing: antialiased !important;
	transition: all 0.5s;
	h6 {
		animation-namecolor: $white;
		position: absolute;
		top: 0;
		left: 50%;
		text-align:center;
		transform: translateX(-50%) translateY(0);
		background: rgba(0, 0, 0, 0.4);
		padding: 1rem;
		width:100%;
	}
	&:hover {
		transform: scale3d(1.05, 1.05, 1) rotate(0.0001deg);
		box-shadow: $dp-3;
	}
}
.rollover-scale-10 {
	&:hover {
		transform: scale3d(1.10, 1.10, 1) rotate(0.0001deg);
		box-shadow: $dp-4;
  	}
}
.rollover-scale-20 {
	&:hover {
		transform: scale3d(1.20, 1.20, 1) rotate(0.0001deg);
		box-shadow: $dp-5;
	}
}
/* Roll up text */
.rollover-roll-text {
	.caption {
		color: $white;
		position: absolute;
		width: 100%;
		height: 0;
		overflow: hidden;
		bottom: 0;
		left: 0;
		right: 0;
		text-align:center;
		background: rgba(0, 0, 0, 0.5);
		transition: height 0.3s;

		.caption-inner {
			position: absolute;
			top: 40%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
	    }
	}
	.caption-title {
		background: #cd2027;
		position:absolute; 
		bottom:0; 
		width:100%;
    }	
	&:hover {
    	.caption { height: 100%; }
  	}
}
/* Rollover fade with text */
.rollover-fade-text {
	.caption {
		color: $white;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		overflow: hidden;
		text-align:center;
		background: rgba(0, 0, 0, 0.5);
		opacity: 0;
		transition: opacity 0.2s;

		.caption-inner {
			position: absolute;
			width: 100%;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}
	&:hover {
		.caption {opacity: 1;}
	}
}



/* image gallery */
.product-image-gallery {
	padding: 0;
 	li {
		list-style-type: none;
		float: left;
		display: none;
		padding: 3px;

		&:first-child {
			display: block;
		}
		img {
			width: 100%;
			height: 100%;
			max-height: 100%;
		}
		.card-block {
			p {
				display: block;
				text-overflow: ellipsis;
				word-wrap: break-word;
				overflow: hidden;
				max-height: 3em;
				line-height: 1.5em;
				margin-bottom: 0;
			}
		}
	}
}
.product-image-gallery-nav {
	padding: 0;
	li {
		list-style-type: none;
		float: left;
		/*max-width: 147px;  1920 width mainly the max for this */
		display: none;
		margin: 0 0.25em;
		transition: border-bottom 0.1s;
		border-bottom: 0.25em solid $white;
		img {
			padding: 0.25em 0;
			&:hover {
				cursor: pointer;
			}
		}
	}
	li.slick-current {
		border-bottom: 0.25em solid $brand-secondary;
	}
}

// Gallery - Personnel
.page-template-template-features-gallery-portfolio {
	#gallery-personnel-flip {
		/* entire container, keeps perspective */
		.flip-container {	
			perspective: 1000px; 
		}
		/* flip the pane when hovered */
		.flip-container:hover .flipper, .flip-container.hover .flipper {
			transform: rotateY(180deg);
		}
		.flip-container, .front, .back {
			width: 100%;
			height: 130px;
			overflow: hidden;
		}
		/* flip speed goes here */
		.flipper {
			transition: 0.6s;
			transform-style: preserve-3d;
			position: relative;
		}

		/* hide back of pane during swap */
		.front, .back {
		backface-visibility: hidden;
			position: absolute;
			top: 0;
			left: 0;
		}
		/* front pane, placed above back */
		.front {
			z-index: 2;
			/* for firefox 31 */
			transform: rotateY(0deg);
		}
		/* back, initially hidden pane */
		.back {
			transform: rotateY(180deg);
		}
	}
}


#gallery-personnel .member-wrap {
  text-align: center;
  padding: 5px;
}
#gallery-personnel .member-wrap a:hover {
  cursor: pointer;
}
#gallery-personnel .member-wrap .pic {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border: 4px solid #286898;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 0px 2px rgba(0,0,0,0.25);
  box-shadow: 0 2px 0px 2px rgba(0,0,0,0.25);

  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
#gallery-personnel .member-wrap .pic:hover {
  position: relative;
  cursor: pointer;
  border: 4px solid #f2ab2f;
  -moz-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -o-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 4px 3px 2px rgba(0,0,0,0.25);
  box-shadow: 0 4px 3px 2px rgba(0,0,0,0.25);
}
#gallery-personnel .member-wrap .pic img {
  width: 100%;
}
#gallery-personnel .member-wrap .pic-wrap {
  position: relative;
}
#gallery-personnel .member-wrap .pic-wrap .close-link {
  display: none;
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 44px;
  height: 44px;
  background: #f2ab2f;
  border-radius: 100%;
}
#gallery-personnel .member-wrap .pic-wrap .close-link:hover {
  cursor: pointer;
}
#gallery-personnel .member-wrap .pic-wrap .close-link img {
  width: auto;
  margin-top: 7px;
}


#gallery-masonry {
	/* ---- grid ---- */
	.grid-sizer { width: 100%; }
	.grid {
		width: 100%;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
		.grid-item {
			width: 100%;
			float: left;
			img { width: 100%;height: 100%; }
			&--width2 { width: 100%; }
			&--width3 { width: 100%; }
			&--width4 { width: 100%; }
    	}
		@media screen and (min-width: 400px) {
			.grid-sizer {width: 50%;}
			.grid-item { width: 50%; }
			.grid-item--width2 { width: 50%; }
			.grid-item--width3 { width: 75%; }
			.grid-item--width4 { width: 100%; }
		}
		@media screen and (min-width: 700px) {
			.grid-sizer { width: 33%; }
			.grid-item { width: 33%; }
			.grid-item--width2 { width: 33%; }
			.grid-item--width3 { width: 66%; }
			.grid-item--width4 { width: 100%; }
		}
		@media screen and (min-width: 900px) {
			.grid-sizer {width: 25%;}
			.grid-item {width: 25%;}
			.grid-item--width2 { width: 50%; }
			.grid-item--width3 { width: 75%; }
			.grid-item--width4 { width: 100%; }
		}
	}
} 




/*  media queries  */
@media (max-width: 1200px) {
  .content {
    .row-featured-products {
      & > div > p { min-height: 72px; /* 3 lines */ }
      & > div > .btn { margin-bottom: 2em; }
    }
  }
}

@media (max-width: 992px) {
  .content {
    .row-featured-products {
      & > div > p { min-height: 48px; /* 2 lines */ }
    }
  }
}

@media (max-width: 768px) {
  .content {
    .row-featured-products {
      & > div > p { min-height: 48px; /* 2 lines */ }
    }
    .tab-content {img {  max-width: 30%;}
	}
    .btt-anchor { padding-top: 0;}
  }
  #slider {
    .slide-text { margin: 0;}
    .slide-left { padding: 0 15px 3rem 15px;}
    .slide-right { 
	   padding: 0 15px 0 15px;
      .row > .col {padding: 3rem 15px; }
    }
  }
  .home #mission > .container { background: none; background-image: none; }
}

@media (max-width: 544px) {
  .content {
    .row-featured-products {
      & > div > p { min-height: 96px; /* 4 lines */ }
      & > div > .btn { margin-bottom: 1em; }
    }
  }
}

// Webkit
@-webkit-keyframes wobble  {
  0%  { -webkit-transform:  rotate(0deg); }
  20%  { -webkit-transform:  rotate(2deg); }
  50%  { -webkit-transform:  rotate(-2deg); }
  100%  { -webkit-transform:  rotate(0deg); }
}